<template>
    <BT-Blade-Item
        bladeName="delivery-archive"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        navigation="delivery-archives"
        :onPullSuccessAsync="pullDelivery"
        title="Archived Delivery">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-center align-center my-4">
                    <BT-Entity 
                        navigation="public-companies"
                        :itemValue="item.sellerID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <BT-Field-Company
                                :id="item.sellerID"
                                :title="item.companyName" />
                        </template>
                    </BT-Entity>
                    <v-icon class="mx-1">mdi-arrow-right-bold</v-icon>
                    <BT-Entity 
                        navigation="public-companies"
                        :itemValue="item.buyerID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <BT-Field-Company
                                :id="item.buyerID"
                                :title="item.companyName" />
                        </template>
                    </BT-Entity>
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Entity 
                        navigation="public-locations"
                        :itemValue="item.departureLocationID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <BT-Field-Address
                                label="PICK UP FROM"
                                :value="item" />
                        </template>
                    </BT-Entity>
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Entity 
                        navigation="public-locations"
                        :itemValue="item.destinationLocationID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <BT-Field-Address
                                label="DELIVER TO"
                                :value="item" />
                        </template>
                    </BT-Entity>
                </v-col>

                <v-col class="d-none d-sm-flex" cols="4" />

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        label="Due Departure On"
                        shortDateAndTime
                        v-model="item.dueDepartureOn" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        label="Due Arrival On"
                        shortDateAndTime
                        v-model="item.dueArrivalOn" />
                </v-col>

                <v-col cols="6" sm="4" class="d-flex align-center">
                    <div class="warning--text ml-4">
                        <span v-if="!item.isPickup && item.isAutoDeliver">**Auto Pick Up</span>
                        <span v-else-if="item.deliveredByUserID == null && item.deliveredOn != null">**Auto Deliver</span>
                        <span v-else-if="item.isPickup">**Pick Up</span>
                        <span v-else>**Deliver</span>
                    </div>
                </v-col>

                <v-col cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-draw</v-icon>
                        Proof Of Release
                    </v-subheader>
                    <BT-Signature
                        class="ml-4 mb-1"
                        height="150px"
                        v-model="item.proofOfRelease" />
                    <span v-if="item.releasedOn != null" class="ml-4 text-caption">
                        <span>By</span>
                        <BT-Entity
                            alternateText=" unknown "
                            navigation="public-users"
                            inline
                            :itemValue="item.releasedByUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache />
                        <span>at {{ item.releasedOn | toShortDateAndTime }}</span>
                    </span>
                </v-col>

                <v-col cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-draw</v-icon>
                        Proof Of Delivery
                        <v-spacer />
                        <span>Signer: {{ item.receiverName || '(Unknown)' }}</span>
                    </v-subheader>
                    <BT-Signature
                        class="ml-4 mb-1"
                        height="150px"
                        v-model="item.proofOfDelivery" />
                    <span v-if="item.deliveredOn != null" class="ml-4 text-caption">
                        <span>By</span>
                        <BT-Entity
                            alternateText=" unknown "
                            navigation="public-users"
                            inline
                            :itemValue="item.deliveredByUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache />
                        <span>at {{ item.deliveredOn | toShortDateAndTime }}</span>
                    </span>
                </v-col>

                <v-col cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-camera</v-icon>
                        Photo
                        <v-spacer />
                        <BT-Btn
                            v-if="item.lat != null && item.lng != null"
                            :href="`https://maps.google.com/?q=${item.lat},${item.lng}`"
                            inline
                            label="Check Map"
                            leftIcon="mdi-map-marker-radius"
                            small
                            target="_blank" />
                    </v-subheader>
                    <BT-Photo
                        height="150px"
                        :icon="null"
                        :id="item.id" />
                </v-col>

                <v-col cols="12" sm="4">
                    <v-divider class="my-2" />
                    <BT-Entity 
                        navigation="public-users"
                        :itemValue="item.receivedByUserID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Receiver</v-list-item-subtitle>
                                    <v-list-item-title>{{ item | toUserLine }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Entity>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-divider class="my-2" />
                    <BT-Field-Text-Area
                        v-if="item.driverNotes != null"
                        v-model="item.driverNotes"
                        label="Driver Notes" />
                </v-col>

                <BT-Snack v-model="msg" />

                <v-col cols="12">
                    <BT-Table
                        :canRefresh="false"
                        :canSelect="false"
                        class="mt-4"
                        :headers="[
                            { text: 'Packages', value: 'measurement', display: true, title: 1 },
                            { text: 'Packed', value: 'quantityPacked', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Packed: ' },
                            { text: 'Released', value: 'quantityReleased', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Released: ' },
                            { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 3, prefix: 'Delivered: ' },
                            { text: 'Received', value: 'quantityReceived', textFilter: 'toDisplayNumber', subtitle: 4, prefix: 'Received: ' }]"
                        hideActions
                        :items="item.packagedItems">
                        <template v-slot:measurement="{ item }">
                            <BT-Entity 
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementName" />
                        </template>
                        <template v-slot:listItem="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <BT-Entity 
                                        navigation="measurements"
                                        :itemValue="item.measurementID"
                                        itemText="measurementName" />
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Packed: {{ item.quantityPacked | toDisplayNumber }} | Released: {{ item.quantityReleased | toDisplayNumber }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Delivered: {{ item.quantityDelivered | toDisplayNumber }} | Received: {{ item.quantityReceived | toDisplayNumber }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Table>
                </v-col>
            </v-row>
            <!--
            <v-expansion-panels v-model="panelV">
                <BT-Blade-Expansion-Items
                    :canSelect="false"
                    :canRefresh="false"
                    :headers="[
                        { text: 'Name', value: 'measurement', display: true, title: 1 },
                        { text: 'Released', value: 'quantityReleased', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Released: ' },
                        { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 3, prefix: 'Delivered: ' }]"
                    hideActions
                    :items="item.packagedItems"
                    title="Packages">
                    <template v-slot:measurement="{ item }">
                        <BT-Entity 
                            navigation="measurements"
                            :itemValue="item.measurementID"
                            itemText="measurementName" />
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels> -->

            
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';
import { measurementArea } from '~helpers';

export default {
    name: 'Delivery-Archive-Blade',
    components: {
        BTPhoto: () => import('~components/BT-Photo.vue'),
        BTSignature: () => import('~components/BT-Signature.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            msg: null
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        pullDelivery(release) {
            if (this.isLengthyArray(release.packagedItems)) {
                release.packagedItems.sort(firstBy(x => measurementArea(x)));
            }

            return release;
        }
    }
}
</script>